import React from 'react'
import { Link } from 'gatsby'
import classnames from 'classnames'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'

import Layout from '../components/layout'
import SEO from '../components/seo'
import PageHeader from '../components/PageHeader'

import explorer1 from '../images/explorer-1.jpg'
import arrow from '../images/cta-arrow.svg'

const RecommendedBooksPage = props => {
  const {
    recommendedBooksPage: page,
    books: recommendedBooks,
    featuredBook: bookOfTheWeek,
    allFocusAreas,
    focusAreaUid,
  } = props.pageContext

  const hasPreviousPage = props.pageContext.currentPage > 1
  const hasNextPage = props.pageContext.currentPage < props.pageContext.numPages

  return (
    <Layout>
      <SEO title={page.title} keywords={[`longevity`]} />

      <PageHeader titles={[page.title]} richDescription={page.description} />

      <div className="container lg:max-w-xl px-8 lg:px-0 mx-auto font-serif text-blue-dianne leading-tight">
        {bookOfTheWeek && (
          <>
            <h2 className="lg:hidden text-2xl font-normal mt-32">
              {page.featured_section_title}
            </h2>
            <div className="flex flex-col-reverse flex-wrap lg:flex-row my-16 lg:my-32">
              <>
                <div className="w-full lg:w-2/3 lg:pl-32 lg:pr-16">
                  <h2 className="hidden lg:block text-2xl font-normal">
                    {page.featured_section_title}
                  </h2>
                  <h3 className="text-lg lg:text-xl font-normal mt-8">
                    {bookOfTheWeek.book_title}
                  </h3>
                  <h4 className="text-base lg:text-lg font-normal mt-4">
                    {bookOfTheWeek.book_author}
                  </h4>
                  <p className="text-base leading-loose font-sans mt-4">
                    {bookOfTheWeek.book_description}
                  </p>
                </div>
                <div className="w-full lg:w-1/3">
                  <img
                    src={bookOfTheWeek.book_cover.url || explorer1}
                    alt="recommended book"
                  />
                </div>
              </>
            </div>
          </>
        )}

        <div className="my-32">
          <div className="lg:hidden flex">
            <select
              className="mb-16 font-sans bg-white"
              defaultValue={
                focusAreaUid
                  ? `/recommended-books/${focusAreaUid}`
                  : '/recommended-books'
              }
              onChange={event => {
                window.location.href = event.target.value
              }}
            >
              <option value="/recommended-books">All</option>
              {allFocusAreas.map((focusArea, index) => (
                <option
                  value={`/recommended-books/${focusArea.uid}`}
                  key={index}
                >
                  {focusArea.name}
                </option>
              ))}
            </select>
            <div>
              <FontAwesomeIcon icon={faCaretDown} />
            </div>
          </div>
          <div className="hidden lg:block w-full">
            <ul
              id="filter"
              className="list-reset flex items-center font-sans -ml-5 mb-16 justify-center lg:justify-start"
            >
              <li className="p-5">
                <a
                  href="/recommended-books"
                  className={classnames(
                    'text-blue-dianne no-underline hover:underline',
                    {
                      'font-bold': !focusAreaUid,
                    }
                  )}
                >
                  All
                </a>
              </li>
              {allFocusAreas.map((focusArea, index) => (
                <li className="p-5" key={index}>
                  <a
                    href={`/recommended-books/${focusArea.uid}`}
                    className={classnames(
                      'text-blue-dianne no-underline hover:underline',
                      {
                        'font-bold': focusArea.uid === focusAreaUid,
                        'text-age-science':
                          focusArea.uid === 'age-science' &&
                          focusArea.uid === focusAreaUid,
                        'text-preventive-healthcare':
                          focusArea.uid === 'preventive-healthcare' &&
                          focusArea.uid === focusAreaUid,
                        'text-longevity-economy':
                          focusArea.uid === 'longevity-economy' &&
                          focusArea.uid === focusAreaUid,
                      }
                    )}
                  >
                    {focusArea.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          {recommendedBooks.map(
            (
              { book_title, book_cover, book_author, book_description },
              index
            ) => (
              <div className="lg:flex mb-16" key={index}>
                <div className="w-1/2 lg:w-1/4 flex-no-shrink">
                  <img src={book_cover.url || explorer1} alt="" />
                </div>
                <div className="lg:pl-8 lg:pr-32">
                  <h3 className="text-lg lg:text-xl font-normal mt-4">
                    {book_title}
                  </h3>
                  <h4 className="text-base lg:text-lg font-normal mt-4">
                    {book_author}
                  </h4>
                  <p className="text-base leading-loose font-sans mt-4">
                    {book_description}
                  </p>
                </div>
              </div>
            )
          )}
          {props.pageContext.numPages && (
            <ul
              id="pagination"
              className="list-reset flex items-center font-sans my-16 lg:my-24 flex-wrap justify-start px-4 lg:px-0"
            >
              <li className="w-1/3">
                <Link
                  to={`/recommended-books${
                    focusAreaUid ? `/${focusAreaUid}` : ''
                  }${
                    props.pageContext.currentPage === 2
                      ? ''
                      : `/${props.pageContext.currentPage - 1}`
                  }`}
                  className={classnames(
                    'font-serif text-base text-blue-dianne flex items-center no-underline',
                    {
                      invisible: !hasPreviousPage,
                    }
                  )}
                >
                  <img
                    src={arrow}
                    alt=""
                    className="mr-4 lg:mr-8 h-6 svg-mirror"
                  />
                  {/* <span className="cta-ie11-fix">Previous</span>{' '} */}
                </Link>
              </li>
              <li style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                <p className="font-serif text-blue-dianne">
                  Page {props.pageContext.currentPage} of{' '}
                  {props.pageContext.numPages}
                </p>
              </li>
              <li className="w-1/3">
                <Link
                  to={`/recommended-books${
                    focusAreaUid ? `/${focusAreaUid}/` : '/'
                  }${props.pageContext.currentPage + 1}`}
                  className={classnames(
                    'font-serif text-base text-blue-dianne flex items-center justify-end no-underline',
                    {
                      invisible: !hasNextPage,
                    }
                  )}
                >
                  {/* <span className="cta-ie11-fix">Next</span>{' '} */}
                  <img src={arrow} alt="" className="ml-4 g:ml-8 h-6" />
                </Link>
              </li>
            </ul>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default RecommendedBooksPage
